import React, { useState, useEffect } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import {
  useUpdatePersonMutation,
  useUploadPersonImageMutation,
} from "../../slices/peopleApiSlice";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

const PeopleEdit = ({ show, handleClose, person }) => {
  // State variables for form fields
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [twitter, setTwitter] = useState("");
  const [image, setImage] = useState("");

  // Update state when `publication` prop changes
  useEffect(() => {
    if (person) {
      setTitle(person.title);
      setName(person.name);
      setDescription(person.description);
      setImage(person.image);
      setTwitter(person.twitter);
    }
  }, [person]);

  const [updatePerson, { isLoading: loadingUpdate }] =
    useUpdatePersonMutation();
  const [uploadPersonImage, { isLoading: loadingUpload }] =
    useUploadPersonImageMutation();

  // Handle form submission
  const handleSaveChanges = async () => {
    if (person) {
      const updatedData = {
        _id: person._id,
        name,
        title,
        twitter,
        description,
        image,
      };
      await updatePerson(updatedData);
      toast.success("Person updated successfully");
      handleClose();
    }
  };

  const uploadFileHandler = async (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]); // Changed from "image" to "file"
    try {
      const res = await uploadPersonImage(formData).unwrap();
      console.log("Upload response:", res.file.filename);
      toast.success(res.file.message);

      setImage(res.file.filename); // Ensure this is the correct response attribute
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  // Return JSX with controlled components
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Person</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              value={name}
              placeholder="Enter Name"
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Image</Form.Label>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Form.Control
                type="text"
                value={image}
                onChange={(e) => setImage(e.target.value)}
                placeholder="Enter Image URL"
              />
              {loadingUpload && (
                <Spinner
                  animation="border"
                  role="status"
                  style={{ marginLeft: "10px" }}
                >
                  <span className="visually-hidden">Uploading...</span>
                </Spinner>
              )}
            </div>
            <Form.Control
              type="file"
              label="Choose file"
              onChange={uploadFileHandler}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter Title of Person"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Write a fun description..."
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Twitter</Form.Label>
            <Form.Control
              type="text"
              value={twitter}
              onChange={(e) => setTwitter(e.target.value)}
              placeholder="Enter Twitter if available"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={handleSaveChanges}
          disabled={loadingUpdate || loadingUpload}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PeopleEdit;

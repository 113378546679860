import { React } from "react";
import Navbar from "./components/layouts/NavBar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./components/pages/Home";
import Contact from "./components/pages/Contact";
import People from "./components/pages/People";
import Publication from "./components/pages/Publication";
import Research from "./components/pages/Research";
import News from "./components/pages/News";
import Footer from "./components/layouts/Footer";
import Admin from "./components/pages/Admin";
import NewsList from "./components/adminPages/NewsList";

import AdminRoute from "./components/layouts/AdminRoute";

import ResearchState from "./context/ResearchState";

import "bootstrap/dist/css/bootstrap.min.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PublicationList from "./components/adminPages/PublicationList";
import PeopleList from "./components/adminPages/PeopleList";

const App = () => {
  return (
    <>
      <ResearchState>
        <Router>
          <div className="background1">
            <Navbar />
            <div className="content">
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route path="/news" element={<News />} />
                <Route path="/admin" element={<Admin />} />
                <Route path="/research" element={<Research />} />
                <Route path="/publications" element={<Publication />} />
                <Route path="/people" element={<People />} />
                <Route path="/contact" element={<Contact />} />
              </Routes>
              <Routes>
                <Route path="" element={<AdminRoute />}>
                  <Route path="/admin/news" element={<NewsList />} />
                  <Route
                    path="/admin/publications"
                    element={<PublicationList />}
                  />
                  <Route path="/admin/people" element={<PeopleList />} />
                </Route>
              </Routes>
            </div>
            <Footer />
            <ToastContainer />
          </div>
        </Router>
      </ResearchState>
    </>
  );
};

export default App;

import { NEWS_URL, UPLOAD_URL } from "../constants";
import { apiSlice } from "./apiSlice";

export const newsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getNews: builder.query({
      query: () => ({
        url: NEWS_URL,
      }),
      providesTags: ["News"],
      keepUnusedDataFor: 5,
    }),
    getNewsDetails: builder.query({
      query: (newsID) => ({
        url: `${NEWS_URL}/${newsID}`,
      }),
      keepUnusedDataFor: 5,
    }),
    createNews: builder.mutation({
      query: () => ({
        url: NEWS_URL,
        method: "POST",
      }),
      invalidatesTags: ["News"],
    }),
    updateNews: builder.mutation({
      query: (data) => ({
        url: `${NEWS_URL}/${data._id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["News"],
    }),
    uploadNewsImage: builder.mutation({
      query: (imageFormData) => ({
        url: UPLOAD_URL,
        method: "POST",
        body: imageFormData,
        // Remove the manually set 'Content-Type' header
      }),
      invalidatesTags: ["News"],
    }),

    deleteNews: builder.mutation({
      query: (newsId) => ({
        url: `${NEWS_URL}/${newsId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetNewsQuery,
  useCreateNewsMutation,
  useUpdateNewsMutation,
  useGetNewsDetailsQuery,
  useDeleteNewsMutation,
  useUploadNewsImageMutation,
} = newsApiSlice;

import { PEOPLE_URL, UPLOAD_URL } from "../constants";
import { apiSlice } from "./apiSlice";

export const peopleApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPeople: builder.query({
      query: () => ({
        url: PEOPLE_URL,
      }),
      providesTags: ["Person"],
      keepUnusedDataFor: 5,
    }),
    createPerson: builder.mutation({
      query: () => ({
        url: PEOPLE_URL,
        method: "POST",
      }),
      invalidatesTags: ["Person"],
    }),
    updatePerson: builder.mutation({
      query: (data) => ({
        url: `${PEOPLE_URL}/${data._id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Person"],
    }),
    uploadPersonImage: builder.mutation({
      query: (imageFormData) => ({
        url: UPLOAD_URL,
        method: "POST",
        body: imageFormData,
        // Remove the manually set 'Content-Type' header
      }),
      invalidatesTags: ["Person"],
    }),

    deletePerson: builder.mutation({
      query: (personId) => ({
        url: `${PEOPLE_URL}/${personId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetPeopleQuery,
  useCreatePersonMutation,
  useUpdatePersonMutation,
  useUploadPersonImageMutation,
  useDeletePersonMutation,
} = peopleApiSlice;

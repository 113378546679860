import React, { useReducer } from 'react';
import ResearchContext from './researchContext';
import researchReducer from './researchReducer';
import { ENTER_MOBILE, EXIT_MOBILE } from './types';

const ResearchState = (props) => {
  const initialState = {
    mobile: false,
  };

  const [state, dispatch] = useReducer(researchReducer, initialState);

  const enterMobile = () => {
    dispatch({ type: ENTER_MOBILE });
  };

  const exitMobile = () => {
    dispatch({ type: EXIT_MOBILE });
  };

  return (
    <ResearchContext.Provider
      value={{
        enterMobile,
        exitMobile,

        mobile: state.mobile,
      }}
    >
      {props.children}
    </ResearchContext.Provider>
  );
};

export default ResearchState;

import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { Link, useLocation } from 'react-router-dom';

import { Nav } from 'react-bootstrap';

const MobileNavbar = () => {
  const [isOpen, toggle] = useState(false);

  const first = useSpring({
    transform: isOpen
      ? 'translate(5px, 32px) rotate(-45deg)'
      : 'translate(2px, 7px) rotate(0deg)',
  });
  const second = useSpring({
    transform: isOpen
      ? 'translate(10px, 4px) rotate(45deg)'
      : 'translate(2px, 19px) rotate(0deg)',
  });
  const third = useSpring({
    transform: isOpen
      ? 'translate(5px, 32px) rotate(-45deg)'
      : 'translate(2px, 31px) rotate(0deg)',
  });

  const menuAppear = useSpring({
    transform: isOpen ? 'translate3D(0,0,0)' : 'translate3D(0,-40px,0)',
    opacity: isOpen ? 1 : 0,
  });

  const location = useLocation();

  console.log(location.pathname);

  return (
    <>
      <div
        style={{ background: 'black', padding: '20px', textAlign: 'center' }}
      >
        <svg
          onClick={() => toggle(!isOpen)}
          width='40'
          height='32'
          viewBox='0 0 44 44'
          fill='#fafafa'
          xmlns='http://www.w3.org/2000/svg'
        >
          <animated.rect width='40' height='4' rx='2' style={first} />
          <animated.rect width='40' height='4' rx='2' style={second} />
          <animated.rect width='40' height='4' rx='2' style={third} />
        </svg>
      </div>
      <animated.div style={menuAppear}>
        {isOpen && (
          <div className='mobilenav menu'>
            <div className='mobilelist'>
              <Nav.Link
                as={Link}
                to='/news'
                className={
                  location.pathname === '/news' ? 'text2  textactive' : 'text2'
                }
              >
                News
              </Nav.Link>
              <Nav.Link
                as={Link}
                to='/research'
                className={
                  location.pathname === '/research'
                    ? 'text2  textactive'
                    : 'text2'
                }
              >
                Research
              </Nav.Link>
              <Nav.Link
                as={Link}
                to='/publications'
                className={
                  location.pathname === '/publications'
                    ? 'text2  textactive'
                    : 'text2'
                }
              >
                Publications
              </Nav.Link>
              <Nav.Link
                as={Link}
                to='/people'
                className={
                  location.pathname === '/people'
                    ? 'text2  textactive'
                    : 'text2'
                }
              >
                People
              </Nav.Link>
              <Nav.Link
                as={Link}
                to='/contact'
                className={
                  location.pathname === '/contact'
                    ? 'text2  textactive'
                    : 'text2'
                }
              >
                Contact
              </Nav.Link>
            </div>
          </div>
        )}
      </animated.div>

      <h3 className='labtitle'>
        <Link className='labtitlelink mobiletitle' to='/'>
          RNA Systems <br /> Virology <br /> Lab
        </Link>
      </h3>
    </>
  );
};

export default MobileNavbar;

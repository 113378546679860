import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Spinner } from "react-bootstrap";
import {
  useUpdatePublicationMutation,
  useUploadPublicationImageMutation,
  useUploadPublicationPDFMutation,
} from "../../slices/publicationsApiSlice";
import { toast } from "react-toastify";
import { formatDate, convertDateForInput } from "../../utils";

const PublicationEdit = ({ show, handleClose, publication }) => {
  // State variables for form fields
  const [date, setDate] = useState("");
  const [authors, setAuthors] = useState("");
  const [title, setTitle] = useState("");
  const [cover, setCover] = useState("");
  const [issue, setIssue] = useState("");
  const [picture, setPicture] = useState("");
  const [pdf, setPdf] = useState("");

  // Update state when `publication` prop changes
  useEffect(() => {
    if (publication) {
      setDate(convertDateForInput(publication.date)); // Assuming you need to format the date for input
      setAuthors(publication.authors);
      setTitle(publication.title);
      setCover(publication.cover);
      setIssue(publication.issue);
      setPicture(publication.picture);
      setPdf(publication.pdf);
    }
  }, [publication]);

  const [updatePublication, { isLoading: loadingUpdate }] =
    useUpdatePublicationMutation();
  const [uploadPublicationImage, { isLoading: loadingUpload }] =
    useUploadPublicationImageMutation();
  const [uploadPublicationPDF, { isLoading: loadingUploadPdf }] =
    useUploadPublicationPDFMutation();

  // Handle form submission
  const handleSaveChanges = async () => {
    if (publication) {
      const updatedData = {
        _id: publication._id,
        date: formatDate(date), // Assuming you need to format the date for backend
        authors,
        title,
        cover,
        issue,
        picture,
        pdf,
      };

      await updatePublication(updatedData);
      toast.success("Publication updated successfully");
      handleClose();
    }
  };

  const uploadFileHandler = async (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]); // Changed from "image" to "file"
    try {
      const res = await uploadPublicationImage(formData).unwrap();

      toast.success(res.file.message);

      setPicture(res.file.filename); // Ensure this is the correct response attribute
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const uploadPDFHandler = async (e) => {
    const formData = new FormData();
    formData.append("pdf", e.target.files[0]);
    try {
      const res = await uploadPublicationPDF(formData).unwrap();
      toast.success(res.message);
      setPdf(res.pdf);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  // Return JSX with controlled components
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Publication</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Date</Form.Label>
            <Form.Control
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Authors</Form.Label>
            <Form.Control
              type="text"
              value={authors}
              onChange={(e) => setAuthors(e.target.value)}
              placeholder="Enter Authors"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter Title"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Cover</Form.Label>
            <Form.Control
              type="text"
              value={cover}
              onChange={(e) => setCover(e.target.value)}
              placeholder="Enter Cover"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Issue</Form.Label>
            <Form.Control
              type="text"
              value={issue}
              onChange={(e) => setIssue(e.target.value)}
              placeholder="Enter Issue"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Image</Form.Label>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Form.Control
                type="text"
                value={picture}
                onChange={(e) => setPicture(e.target.value)}
                placeholder="Enter Image URL"
              />
              {loadingUpload && (
                <Spinner
                  animation="border"
                  role="status"
                  style={{ marginLeft: "10px" }}
                >
                  <span className="visually-hidden">Uploading...</span>
                </Spinner>
              )}
            </div>
            <Form.Control
              type="file"
              label="Choose file"
              onChange={uploadFileHandler}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>PDF</Form.Label>
            <Form.Control
              type="text"
              value={pdf}
              onChange={(e) => setPdf(e.target.value)}
              placeholder="Enter PDF"
            />
            <Form.Control
              type="file"
              label="Choose file"
              onChange={uploadPDFHandler}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={handleSaveChanges}
          disabled={loadingUpdate || loadingUpload}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PublicationEdit;

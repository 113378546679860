import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useLoginMutation } from "../../slices/usersApiSlice";
import { setCredentials } from "../../slices/authSlice";
import { toast } from "react-toastify";
import Loader from "./Loader";
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  const [login, { isLoading }] = useLoginMutation();

  const { userInfo } = useSelector((state) => state.auth);

  useEffect(() => {
    if (userInfo) {
      console.log(userInfo);
    }
  }, [userInfo]);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const res = await login({ email, password }).unwrap();
      dispatch(setCredentials({ ...res }));
    } catch (err) {
      toast.error(err?.data.message || err?.error, {
        autoClose: 1000, // Duration in milliseconds
        className: "my-toast",
      });
    }
  };

  return (
    <>
      <div className="form-container">
        {" "}
        {/* Added this container */}
        <Form className="admin-form" onSubmit={submitHandler}>
          {" "}
          {/* Added a class here */}
          <Form.Group className="mb-3" controlId="email">
            <Form.Label>Username</Form.Label>
            <Form.Control
              placeholder="Enter Username"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Form.Text className="text-muted">
              Enter Lab Admin Username
            </Form.Text>
          </Form.Group>
          <Form.Group className="mb-3" controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          {isLoading ? (
            <Loader />
          ) : (
            <Button variant="dark" type="submit" disabled={isLoading}>
              Login
            </Button>
          )}
        </Form>
      </div>
    </>
  );
};

export default Login;

import React from "react";
import { useState } from "react";
import { Table, Button, Modal } from "react-bootstrap";
import { Image } from "react-bootstrap";

import { getPDFUrl } from "../../utils";

import { apiBaseUrl } from "../../utils/envVariable";

import moment from "moment";
import { toast } from "react-toastify";
import PublicationEdit from "./PublicationEdit";
import {
  useGetPublicationsQuery,
  useCreatePublicationMutation,
  useDeletePublicationMutation,
} from "../../slices/publicationsApiSlice";
import { AiFillEdit, AiFillFilePdf } from "react-icons/ai";
import { BiTrash } from "react-icons/bi";

import { Link } from "react-router-dom";
import Loader from "../layouts/Loader";

const PublicationList = () => {
  const {
    data: publications,
    isLoading,
    isError,
    error,
    refetch,
  } = useGetPublicationsQuery();

  const [createPublication, { isLoading: loadingCreate }] =
    useCreatePublicationMutation();
  const [deletePublication, { isLoading: loadingDelete }] =
    useDeletePublicationMutation();

  const [showModal, setShowModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteID, setDeleteID] = useState(null);
  const [currentNews, setCurrentNews] = useState(null); // Store the news item to be edited

  const handleShow = (newsItem) => {
    setCurrentNews(newsItem);
    setShowModal(true);
  };
  const handleClose = () => {
    setCurrentNews(null);
    setShowModal(false);
  };

  const handleCreateShow = () => setShowCreateModal(true);
  const handleCreateClose = () => setShowCreateModal(false);

  const handleDeleteShow = (id) => {
    setDeleteID(id);
    setShowDeleteModal(true);
  };
  const handleDeleteClose = () => {
    setDeleteID(null);
    setShowDeleteModal(false);
  };

  const createPublicationHandler = async () => {
    handleCreateClose();
    try {
      const newPubData = await createPublication().unwrap();
      // Assuming newNewsData contains the created news item
      setCurrentNews(newPubData);
      setShowModal(true); // Open the modal for editing
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
  };

  const deleteHandler = async () => {
    try {
      await deletePublication(deleteID);
      refetch();
    } catch (err) {
      toast.err(err?.data?.message || err.error);
    }
    handleDeleteClose();
  };

  const sortedPublications =
    publications && publications.length > 0
      ? [...publications].sort((a, b) => {
          return (
            moment(b.date, "DD/MM/YYYY").toDate() -
            moment(a.date, "DD/MM/YYYY").toDate()
          );
        })
      : [];

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <>Error: {JSON.stringify(error)}</>;
  }

  return (
    <>
      <div className="button-container">
        <Link className="adminlink" to="/admin">
          <Button variant="dark" type="submit" className="backbutton">
            Back
          </Button>{" "}
        </Link>
        <Button
          variant="dark"
          type="submit"
          className="backbutton"
          onClick={handleCreateShow}
        >
          Add Publication
        </Button>{" "}
        <PublicationEdit
          show={showModal}
          handleClose={handleClose}
          publication={currentNews}
        />
      </div>
      <div className="tablecontainer">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Image</th>
              <th>Cover/Title</th>
              <th>Date</th>
              <th>PDF</th>

              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {sortedPublications.map((pub) => (
              <tr key={pub._id}>
                <td>
                  {" "}
                  <figure>
                    <Image
                      className="pubpic"
                      src={apiBaseUrl + pub.picture}
                      alt={pub._id}
                    />
                  </figure>{" "}
                </td>

                <td>
                  <b>{pub.cover}</b> : {pub.title} <br /> <b>Authors:</b>{" "}
                  {pub.authors}
                </td>

                <td> {moment(pub.date, "DD-MM-YYYY").format("YYYY")}</td>
                <td>
                  {" "}
                  <a
                    href={getPDFUrl(pub.pdf)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <AiFillFilePdf
                      style={{
                        color: "black",
                        marginLeft: ".5rem",
                        height: "100%",
                      }}
                    />
                  </a>
                </td>

                <td>
                  <Button variant="link" onClick={() => handleShow(pub)}>
                    <AiFillEdit style={{ color: "black" }} />{" "}
                  </Button>
                </td>
                <td>
                  <Button
                    variant="link"
                    onClick={() => handleDeleteShow(pub._id)}
                  >
                    <BiTrash
                      style={{ color: "#d11b49", marginLeft: ".2rem" }}
                      size="20"
                      width={10}
                    />{" "}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <Modal show={showCreateModal} onHide={handleCreateClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create News</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to add a Publication?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCreateClose}>
            Cancel
          </Button>
          <Button variant="dark" onClick={createPublicationHandler}>
            Add Publication
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteModal} onHide={handleDeleteClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to Delete this Publication?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={deleteHandler}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PublicationList;

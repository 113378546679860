import React from "react";
import { Image } from "react-bootstrap";
import { useGetPublicationsQuery } from "../../slices/publicationsApiSlice";
import { getPDFUrl } from "../../utils";
import { apiBaseUrl } from "../../utils/envVariable";

import moment from "moment";
import Loader from "../layouts/Loader";

const Publication = () => {
  const { data: publications, isLoading, error } = useGetPublicationsQuery();

  const sortedPublications =
    publications && publications.length > 0
      ? [...publications].sort((a, b) => {
          return (
            moment(b.date, "DD/MM/YYYY").toDate() -
            moment(a.date, "DD/MM/YYYY").toDate()
          );
        })
      : [];

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <div>{error?.data?.message || error.error}</div>
      ) : (
        <div>
          <h5 className="pubtitle">Publications</h5>
          <div className="pub">
            {sortedPublications.map((pub) => (
              <div className="pubcontainer" key={pub._id}>
                <figure className="pubimage">
                  <Image
                    className="pubpic"
                    src={apiBaseUrl + pub.picture}
                    alt={pub._id}
                  />
                </figure>{" "}
                <p className="pubtext">
                  {pub.authors}{" "}
                  <a
                    href={getPDFUrl(pub.pdf)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {pub.title}
                  </a>
                  . <b>{pub.cover}</b> {pub.issue} (
                  {moment(pub.date, "DD-MM-YYYY").format("YYYY")})
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Publication;

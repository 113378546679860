import React from 'react';

const Research = () => {
  return (
    <>
      <h5
        style={{
          textAlign: 'center',
          marginTop: '1.5rem',
          fontFamily: 'FinalSixBold',
        }}
      >
        Research Overview
      </h5>

      <div className='researchcontainer'>
        <p className='researchtext'>
          Positive sense RNA virus genomes face a unique challenge in that a
          single RNA is capable of launching infection alongside a multi-faceted
          innate immune response. Research in the Luna Lab aims to define
          dynamic interactions between RNA virus genomes and a constellation of
          RNA binding proteins (RBPs) whose collective activity determines
          infection trajectories in a cell. We use reverse genetic systems for
          hepaci-, flavi- and corona- RNA virus families as model viruses to
          probe cellular responses to infectious RNA. In combination with CRISPR
          based functional genomics, transcriptome profiling, and live-cell
          microscopy, we aim to determine the order of events by which specific
          RBPs regulate viral genomes or cellular RNAs to shape innate immune
          responses. A deeper understanding of these RNA centric processes will
          help illuminate how viruses and cells navigate the stress of
          infection. These insights may further inform disease states that are
          linked to altered innate immune and RBP activities, such as certain
          neurodegenerative diseases and cancers.
        </p>
        <p className='researchtext'>
          In addition, we are committed to training and mentoring the next
          generation of scientists in biomedical research, particularly from
          under-represented groups. We further prioritize scientific outreach
          efforts at all levels to educate, inform and inspire.
        </p>

        <h5
          style={{
            fontFamily: 'FinalSixBold',
            fontSize: '1rem',
            marginTop: '1rem',
          }}
        >
          Research Projects
        </h5>
        <div className='researchtext researchborder'>
          <p>
            The Luna lab explores the interface between molecular virology and
            the systems-level host response to viral infections, with a special
            emphasis on RNA driven processes.
          </p>

          <b>Current Research Projects Include:</b>

          <p>
            <u>1. RNA dynamics during infection and stress.</u> <br /> A common
            cellular feature of RNA virus infection is an abrupt host- or virus-
            driven halt in translation that results in stress granules
            (SGs)–transient accumulations of mRNA, initiation factors, and RBPs
            that phase separate from surrounding cytoplasm. Current efforts aim
            to dissect the role of dynamic SG signaling during RNA virus
            infection, with broader links to RNA metabolism.
          </p>
          <div className='researchimg-container'>
            <img className='researchimage' src='images/cell.gif' alt='' />
          </div>
          <p>
            <u> 2. RNA virus reverse genetics.</u> <br /> Based on efforts to
            establish a non-infectious reverse genetics system for SARS-CoV-2,
            we aim to explore specific RBP focused questions in the context of
            viral RNA replication in cells. This includes studying the role(s)
            of viral RBPs in modulating host immune responses and determining
            how host RBPs impact CoV induced translation shutoffs. We are also
            interested in extending reverse genetics strategies for additional
            viruses that impact human health.
          </p>

          <div className='researchimg-container'>
            <img className='researchimage' src='images/rfig2.png' alt='' />
          </div>

          <p>
            <u> 3. Subcellular refinement of RNA: protein interactions.</u>{' '}
            <br /> The development of transcriptome-wide methods for
            biochemically determining RBP targets has greatly improved our
            understanding of post-transcriptional regulatory networks and
            mechanisms. Current efforts in the lab aim to develop novel tools to
            enable subcellular resolution of RBP interactions with target RNAs.
          </p>
        </div>
        {/*
        <h6
          style={{
            marginTop: '2rem',
            fontWeight: 'bold',
            wordSpacing: '.2rem',
            fontSize: '1.1rem',
          }}
        >
          Introductory Videos
        </h6>
        */}
      </div>
    </>
  );
};

export default Research;

import React from 'react';
import { useState, useContext, useEffect } from 'react';
import ResearchContext from '../../context/researchContext';

import MobileNavbar from './MobileNavbar';

import { Link } from 'react-router-dom';

import { Navbar, Container, Nav } from 'react-bootstrap';

import { CSSTransition } from 'react-transition-group';
import { BsFillMoonFill } from 'react-icons/bs';

const NavBar = () => {
  const researchContext = useContext(ResearchContext);

  const { mobile, enterMobile, exitMobile } = researchContext;

  const [color, setColor] = useState(true);
  const [showButton, setShowButton] = useState(true);

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowResize);

    if (width <= 600) {
      enterMobile();
    } else if (width >= 600) {
      exitMobile();
    }

    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener('resize', handleWindowResize);
  }, [width]);

  const changeColor = () => {
    if (window.scrollY >= 90) {
      setColor(false);
    } else {
      setColor(true);
    }
  };

  window.addEventListener('scroll', changeColor);

  return (
    <>
      {!mobile ? (
        <>
          <h3 className='labtitle'>
            <Link className='labtitlelink' to='/'>
              RNA Systems Virology Lab
            </Link>
          </h3>
          {color ? (
            <CSSTransition
              in={!color}
              timeout={100}
              classNames='alert'
              onEnter={() => setShowButton(false)}
              onExiting={() => setShowButton(true)}
            >
              <Navbar className='navbar1'>
                <Container className='container1'>
                  <Nav className='me-end'>
                    <Nav.Link className='text1' as={Link} to='/news'>
                      News
                    </Nav.Link>
                    <Nav.Link className='text1' as={Link} to='/research'>
                      Research
                    </Nav.Link>
                    <Nav.Link as={Link} to='/publications' className='text1'>
                      Publications
                    </Nav.Link>
                    <Nav.Link as={Link} to='/people' className='text1'>
                      People
                    </Nav.Link>
                    <Nav.Link as={Link} to='/contact' className='text1'>
                      Contact
                    </Nav.Link>
                  </Nav>
                </Container>
              </Navbar>
            </CSSTransition>
          ) : (
            <CSSTransition
              in={!color}
              timeout={200}
              classNames='alert'
              onEnter={() => setShowButton(false)}
              onExiting={() => setShowButton(true)}
            >
              <Navbar className='navbar2' style={{ zindex: 100 }}>
                <Container className='container2'>
                  <Navbar.Brand as={Link} to='/' className='text2 title'>
                    <BsFillMoonFill
                      style={{ marginBottom: '.3rem' }}
                      size={20}
                    />{' '}
                    Luna Lab
                  </Navbar.Brand>
                  <Nav className='me-end'>
                    <Nav.Link className='text2' as={Link} to='/news'>
                      News
                    </Nav.Link>
                    <Nav.Link as={Link} to='/research' className='text2'>
                      Research
                    </Nav.Link>
                    <Nav.Link as={Link} to='/publications' className='text2'>
                      Publications
                    </Nav.Link>
                    <Nav.Link as={Link} to='/people' className='text2'>
                      People
                    </Nav.Link>
                    <Nav.Link as={Link} to='/contact' className='text2'>
                      Contact
                    </Nav.Link>
                  </Nav>
                </Container>
              </Navbar>
            </CSSTransition>
          )}
        </>
      ) : (
        <MobileNavbar />
      )}
    </>
  );
};

export default NavBar;

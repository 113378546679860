import { PUBLICATIONS_URL, UPLOAD_URL } from "../constants";
import { apiSlice } from "./apiSlice";

export const publicationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPublications: builder.query({
      query: () => ({
        url: PUBLICATIONS_URL,
      }),
      providesTags: ["Publication"],
      keepUnusedDataFor: 5,
    }),
    getPublicationDetails: builder.query({
      query: (pubID) => ({
        url: `${PUBLICATIONS_URL}/${pubID}`,
      }),
      keepUnusedDataFor: 5,
    }),
    createPublication: builder.mutation({
      query: () => ({
        url: PUBLICATIONS_URL,
        method: "POST",
      }),
      invalidatesTags: ["Publication"],
    }),
    updatePublication: builder.mutation({
      query: (data) => ({
        url: `${PUBLICATIONS_URL}/${data._id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Publication"],
    }),
    uploadPublicationImage: builder.mutation({
      query: (imageFormData) => ({
        url: UPLOAD_URL,
        method: "POST",
        body: imageFormData,
      }),
      invalidatesTags: ["Publication"],
    }),

    uploadPublicationPDF: builder.mutation({
      query: (data) => ({
        url: `${UPLOAD_URL}/pdf`,
        method: "POST",
        body: data,
      }),
    }),

    deletePublication: builder.mutation({
      query: (publicationId) => ({
        url: `${PUBLICATIONS_URL}/${publicationId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetPublicationsQuery,
  useCreatePublicationMutation,
  useGetPublicationDetailsQuery,
  useUpdatePublicationMutation,
  useUploadPublicationImageMutation,
  useDeletePublicationMutation,
  useUploadPublicationPDFMutation,
} = publicationsApiSlice;

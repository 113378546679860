import React from "react";
import { useState } from "react";
import { Table, Button, Modal, Image } from "react-bootstrap";
import NewsEdit from "./NewsEdit";

import moment from "moment";
import { toast } from "react-toastify";
import {
  useGetNewsQuery,
  useCreateNewsMutation,
  useDeleteNewsMutation,
} from "../../slices/newsApiSlice";
import { AiFillEdit } from "react-icons/ai";
import { BiTrash } from "react-icons/bi";

import { apiBaseUrl } from "../../utils/envVariable";

import { Link } from "react-router-dom";
import { convertDateFormat } from "../../utils";
import Loader from "../layouts/Loader";

const NewsList = () => {
  const { data: news, isLoading, isError, error, refetch } = useGetNewsQuery();

  const [createNews, { isLoading: loadingCreate }] = useCreateNewsMutation();

  const [deleteNews, { isLoading: loadingDelete }] = useDeleteNewsMutation();

  const [showModal, setShowModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteID, setDeleteID] = useState(null);
  const [currentNews, setCurrentNews] = useState(null); // Store the news item to be edited

  const handleShow = (newsItem) => {
    setCurrentNews(newsItem);
    setShowModal(true);
  };
  const handleClose = () => {
    setCurrentNews(null);
    setShowModal(false);
  };

  const handleCreateShow = () => setShowCreateModal(true);
  const handleCreateClose = () => setShowCreateModal(false);

  const handleDeleteShow = (id) => {
    setDeleteID(id);
    setShowDeleteModal(true);
  };
  const handleDeleteClose = () => {
    setDeleteID(null);
    setShowDeleteModal(false);
  };

  const createNewsHandler = async () => {
    handleCreateClose();
    try {
      const newNewsData = await createNews().unwrap();
      // Assuming newNewsData contains the created news item
      setCurrentNews(newNewsData);
      setShowModal(true); // Open the modal for editing
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
  };

  const deleteHandler = async () => {
    try {
      await deleteNews(deleteID);
      refetch();
    } catch (err) {
      toast.err(err?.data?.message || err.error);
    }
    handleDeleteClose();
  };

  const sortedNews =
    news && news.length > 0
      ? [...news].sort((a, b) => {
          return (
            moment(b.date, "YYYY/MM/DD").toDate() -
            moment(a.date, "YYYY/MM/DD").toDate()
          );
        })
      : [];

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <>Error: {JSON.stringify(error)}</>;
  }

  return (
    <>
      <div className="button-container">
        <Link className="adminlink" to="/admin">
          <Button variant="dark" type="submit" className="backbutton">
            Back
          </Button>{" "}
        </Link>
        <Button
          variant="dark"
          type="submit"
          className="backbutton"
          onClick={handleCreateShow}
        >
          Add News
        </Button>{" "}
        <NewsEdit
          show={showModal}
          handleClose={handleClose}
          news={currentNews}
        />
      </div>
      <div className="tablecontainer">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Date</th>
              <th>Description</th>
              <th>Image</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {sortedNews.map((newsItem) => (
              <tr key={newsItem._id}>
                <td>{convertDateFormat(newsItem.date)}</td>
                <td>{newsItem.text}</td>
                {newsItem.image ? (
                  <td>
                    <figure>
                      <Image
                        className="peoplepic"
                        src={apiBaseUrl + newsItem.image}
                        alt={newsItem._id}
                      />
                    </figure>{" "}
                  </td>
                ) : (
                  <td></td>
                )}

                <td>
                  <Button variant="link" onClick={() => handleShow(newsItem)}>
                    <AiFillEdit style={{ color: "black" }} />{" "}
                  </Button>
                </td>
                <td>
                  <Button
                    variant="link"
                    onClick={() => handleDeleteShow(newsItem._id)}
                  >
                    <BiTrash
                      style={{ color: "#d11b49", marginLeft: ".2rem" }}
                      size="20"
                      width={10}
                    />{" "}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <Modal show={showCreateModal} onHide={handleCreateClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create News</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to add news?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCreateClose}>
            Cancel
          </Button>
          <Button variant="dark" onClick={createNewsHandler}>
            Add News
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteModal} onHide={handleDeleteClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to Delete this Publication?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={deleteHandler}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NewsList;

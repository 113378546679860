import { React, useContext } from "react";

import ResearchContext from "../../context/researchContext";

import { Link } from "react-router-dom";

const Footer = () => {
  const your_lat = 41.50438;
  const your_lng = -81.6043;

  const researchContext = useContext(ResearchContext);

  const { mobile } = researchContext;

  const onClick = () => {
    window.open("https://maps.google.com?q=" + your_lat + "," + your_lng);
  };

  return (
    <div className="footer">
      <p>
        <b>RNA Systems Virology Laboratory</b>, Case Western Reserve University,
        Wood Research Building, 2109 Adelbert Rd, Cleveland, OH 44106
      </p>
      {"  "}
      {!mobile && (
        <div style={{ marginLeft: ".2rem", marginRight: ".2rem" }}> | </div>
      )}{" "}
      <div onClick={onClick} className="footerlink">
        {" "}
        Directions
      </div>
      {!mobile && (
        <div style={{ marginLeft: ".2rem", marginRight: ".2rem" }}> | </div>
      )}{" "}
      <Link to="/admin" className="footerlink">
        Admin
      </Link>
    </div>
  );
};

export default Footer;

export function formatDate(date_str) {
  const [year, month, day] = date_str.split("-");
  return `${day}/${month}/${year}`;
}

export function convertDateFormat(dateString) {
  const dateParts = dateString.split("-");
  return `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`;
}

export function normalizeDate(date_str) {
  const [day, month, year] = date_str.split("/");
  return `${month}/${day}/${year}`;
}

export function convertDateForInput(dateStr) {
  if (typeof dateStr === "string") {
    const parts = dateStr.split("/");
    if (parts.length === 3) {
      const [day, month, year] = parts;
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`; // format to YYYY-MM-DD
    } else {
      console.error("Date string does not contain three parts: ", dateStr);
      return ""; // return an empty string or any other default value
    }
  } else {
    console.error("Invalid type for dateStr, expected a string: ", dateStr);
    return ""; // return an empty string or any other default value
  }
}

export function getPDFUrl(pdfPath) {
  if (pdfPath.startsWith("/uploads/")) {
    return `http://localhost:8000${pdfPath}`; // or use an environment variable for the base URL
  }

  // If the path is for a frontend asset, create the path accordingly
  // Assuming that frontend PDFs are stored in the public/assets folder
  return `${pdfPath}`;
}

import { React, useState } from "react";
import { useSpring, a } from "@react-spring/web";
import { Card } from "react-bootstrap";
import { apiBaseUrl } from "../../utils/envVariable";

const Person = ({ person, dataAttribute }) => {
  const [flipped, setFlipped] = useState(false);

  const { transform, opacity } = useSpring({
    opacity: flipped ? 1 : 0,
    transform: `perspective(600px) rotateX(${flipped ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 },
  });

  const _onClick = () => {
    setFlipped(!flipped);
  };

  const onClick = () => {
    window.open("https://twitter.com/" + person.twitter);
  };

  return (
    <>
      <div onClick={_onClick}>
        {flipped && (
          <a.div
            style={{
              opacity,
              transform,
              rotateX: "180deg",
            }}
          >
            <Card className="cardpic">
              <Card.Body className="cardText">
                <Card.Text className="mcardtext" dataAttribute={dataAttribute}>
                  {person.description}
                </Card.Text>
              </Card.Body>
            </Card>
          </a.div>
        )}

        {!flipped && (
          <a.div
            className="peoplepics  card"
            style={{
              opacity: opacity.to((o) => 1 - o),
              transform,
            }}
          >
            <img
              alt="pic"
              className="peoplepics"
              src={apiBaseUrl + person.image}
            />
          </a.div>
        )}
      </div>
      <div className="people-title">
        <p style={{ marginBottom: "0", marginTop: "0" }}>{person.name}</p>
        <p style={{ marginTop: "0", marginBottom: "0" }}>{person.title}</p>

        <p
          onClick={onClick}
          style={{
            marginTop: "0",
            color: "#00ace6",
            cursor: "pointer",
            marginBottom: "1rem",
          }}
        >
          {person.twitter}
        </p>
      </div>
    </>
  );
};

export default Person;

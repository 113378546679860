import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Spinner } from "react-bootstrap";
import {
  useUpdateNewsMutation,
  useUploadNewsImageMutation,
} from "../../slices/newsApiSlice";
import { toast } from "react-toastify";

const NewsEdit = ({ show, handleClose, news }) => {
  const [date, setDate] = useState("");
  const [text, setText] = useState("");
  const [image, setImage] = useState(""); // Assuming you have an image field in your news data

  useEffect(() => {
    if (news) {
      setDate(news.date); // Assuming `news.date` is in a suitable format for the input
      console.log(news.date);
      setText(news.text);
      setImage(news.image); // Assuming `news.image` contains the image URL or identifier
    }
  }, [news]);

  const [updateNews, { isLoading: loadingUpdate }] = useUpdateNewsMutation();
  const [uploadNewsImage, { isLoading: loadingUpload }] =
    useUploadNewsImageMutation();

  const handleSaveChanges = async () => {
    if (news) {
      const updatedData = {
        _id: news._id,
        date,
        text,
        image,
      };
      console.log(updatedData);
      await updateNews(updatedData);
      toast.success("News updated successfully");
      handleClose();
    }
  };

  const uploadFileHandler = async (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]); // Changed from "image" to "file"
    try {
      const res = await uploadNewsImage(formData).unwrap();
      console.log("Upload response:", res.file.filename);
      toast.success(res.file.message);

      setImage(res.file.filename); // Ensure this is the correct response attribute
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit News</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={(e) => e.preventDefault()}>
          <Form.Group className="mb-3">
            <Form.Label>Date</Form.Label>
            <Form.Control
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Text</Form.Label>
            <Form.Control
              type="text"
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Image</Form.Label>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Form.Control
                type="text"
                value={image}
                onChange={(e) => setImage(e.target.value)}
                placeholder="Enter Image URL"
              />
              {loadingUpload && (
                <Spinner
                  animation="border"
                  role="status"
                  style={{ marginLeft: "10px" }}
                >
                  <span className="visually-hidden">Uploading...</span>
                </Spinner>
              )}
            </div>
            <Form.Control
              type="file"
              label="Choose file"
              onChange={uploadFileHandler}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={handleSaveChanges}
          disabled={loadingUpdate}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewsEdit;

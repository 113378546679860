import { React, useState, useEffect } from "react";

import { useSpring, a } from "react-spring";

import Slider from "react-slick";

import { BiNews } from "react-icons/bi";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

import { RiFilePaper2Line } from "react-icons/ri";

import { Link } from "react-router-dom";

import { useGetNewsQuery } from "../../slices/newsApiSlice";

import { useGetPublicationsQuery } from "../../slices/publicationsApiSlice";

import moment from "moment";

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";

const Home = () => {
  const { data: news } = useGetNewsQuery();
  const { data: publications } = useGetPublicationsQuery();

  useEffect(() => {
    setVisible(false);
  }, []);

  const NextArrow = ({ onClick }) => {
    return (
      <div onClick={onClick} className="arrow next">
        <FaArrowRight />
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div onClick={onClick} className="arrow prev">
        <FaArrowLeft />
      </div>
    );
  };

  const [visible, setVisible] = useState(false);
  const { opacity } = useSpring({
    config: { duration: 500 },
    opacity: visible ? 1 : 0,
    from: { opacity: visible ? 0 : 1 },
  });

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    useTransform: false,
    lazyLoad: "progressive",

    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const sortedNews =
    news && news.length > 0
      ? [...news].sort((a, b) => {
          return (
            moment(b.date, "YYYY/MM/DD").toDate() -
            moment(a.date, "YYYY/MM/DD").toDate()
          );
        })
      : [];

  let recentNews = sortedNews.slice(0, 4);

  const sortedPub =
    publications && publications.length > 0
      ? [...publications].sort((a, b) => {
          return (
            moment(b.date, "DD/MM/YYYY").toDate() -
            moment(a.date, "DD/MM/YYYY").toDate()
          );
        })
      : [];

  let recentPub = sortedPub.slice(0, 4);

  return (
    <>
      <div className="slidercontainer">
        {opacity !== 0 && (
          <a.div style={{ opacity: opacity.to((o) => 1 - o) }}>
            <Slider {...settings} className="slider">
              <div>
                <img
                  src="/images/home.gif"
                  alt="Second slide"
                  className="carouselpic  "
                />
              </div>
              <div>
                <img
                  src="/images/home2.png"
                  alt="Second slide"
                  className="carouselpic  "
                />
              </div>
              <div>
                <img
                  src="/images/wood.jpg"
                  alt="Second slide"
                  className="carouselpic  "
                />
              </div>
            </Slider>
          </a.div>
        )}
      </div>
      <div className="listContainer">
        <List className="list">
          <ListSubheader className="listHeader">Latest News</ListSubheader>
          {recentNews.map((n) => (
            <ListItem className="listitm" key={n.date}>
              <Link to="/news">
                <ListItemIcon className="icon">
                  <BiNews />
                </ListItemIcon>
              </Link>
              <ListItemText className="listText">
                {moment(n.date, "YYYY/MM/DD").format("MMM DD")} - {n.text}{" "}
              </ListItemText>
            </ListItem>
          ))}
        </List>

        <List className="list">
          <ListSubheader className="listHeader">
            Recent Publications
          </ListSubheader>
          {recentPub.map((n) => (
            <ListItem className="listitm" key={n._id}>
              <ListItemIcon className="icon">
                <a href={n.pdf} target="_blank" rel="noopener noreferrer">
                  {" "}
                  <RiFilePaper2Line className="icon2" />{" "}
                </a>
              </ListItemIcon>
              <ListItemText className="listText" primary={n.title} />
            </ListItem>
          ))}
        </List>
      </div>
    </>
  );
};

export default Home;

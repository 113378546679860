import React from "react";
import { useState } from "react";
import { Table, Button, Modal, Image } from "react-bootstrap";
import PeopleEdit from "./PeopleEdit";

import { apiBaseUrl } from "../../utils/envVariable";

import { toast } from "react-toastify";
import {
  useGetPeopleQuery,
  useCreatePersonMutation,
  useDeletePersonMutation,
} from "../../slices/peopleApiSlice";
import { AiFillEdit } from "react-icons/ai";
import { BiTrash } from "react-icons/bi";

import { Link } from "react-router-dom";
import Loader from "../layouts/Loader";

const PeopleList = () => {
  const {
    data: people,
    isLoading,
    isError,
    error,
    refetch,
  } = useGetPeopleQuery();

  const [createPerson, { isLoading: loadingCreate }] =
    useCreatePersonMutation();

  const [deletePerson, { isLoading: loadingDelete }] =
    useDeletePersonMutation();

  const [showModal, setShowModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteID, setDeleteID] = useState(null);
  const [currentNews, setCurrentNews] = useState(null); // Store the news item to be edited

  const handleShow = (newsItem) => {
    setCurrentNews(newsItem);

    setShowModal(true);
  };

  const handleClose = () => {
    setCurrentNews(null);
    setShowModal(false);
  };

  const handleCreateShow = () => setShowCreateModal(true);
  const handleCreateClose = () => setShowCreateModal(false);

  const handleDeleteShow = (id) => {
    setDeleteID(id);
    setShowDeleteModal(true);
  };
  const handleDeleteClose = () => {
    setDeleteID(null);
    setShowDeleteModal(false);
  };

  const createNewsHandler = async () => {
    handleCreateClose();
    try {
      const newPersonData = await createPerson().unwrap();
      // Assuming newNewsData contains the created news item
      setCurrentNews(newPersonData);
      setShowModal(true); // Open the modal for editing
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
  };

  const deleteHandler = async () => {
    try {
      await deletePerson(deleteID);
      refetch();
    } catch (err) {
      toast.err(err?.data?.message || err.error);
    }
    handleDeleteClose();
  };

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <>Error: {JSON.stringify(error)}</>;
  }

  return (
    <>
      <div className="button-container">
        <Link className="adminlink" to="/admin">
          <Button variant="dark" type="submit" className="backbutton">
            Back
          </Button>{" "}
        </Link>
        <Button
          variant="dark"
          type="submit"
          className="backbutton"
          onClick={handleCreateShow}
        >
          Add Person
        </Button>{" "}
        <PeopleEdit
          show={showModal}
          handleClose={handleClose}
          person={currentNews}
        />
      </div>
      <div className="tablecontainer">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Image</th>
              <th>Name</th>
              <th>Title</th>
              <th>Description</th>
              <th>Twitter</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {people.map((newsItem) => (
              <tr key={newsItem._id}>
                <td>
                  {" "}
                  <figure>
                    <Image
                      className="peoplepic"
                      src={apiBaseUrl + newsItem.image}
                      alt={newsItem._id}
                    />
                  </figure>{" "}
                </td>
                <td>{newsItem.name}</td>
                <td>{newsItem.title}</td>

                <td>{newsItem.description}</td>
                <td>{newsItem.twitter}</td>
                <td>
                  <Button variant="link" onClick={() => handleShow(newsItem)}>
                    <AiFillEdit style={{ color: "black" }} />{" "}
                  </Button>
                </td>
                <td>
                  <Button
                    variant="link"
                    onClick={() => handleDeleteShow(newsItem._id)}
                  >
                    <BiTrash
                      style={{ color: "#d11b49", marginLeft: ".2rem" }}
                      size="20"
                      width={10}
                    />{" "}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <Modal show={showCreateModal} onHide={handleCreateClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create Person</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to add a Person?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCreateClose}>
            Cancel
          </Button>
          <Button variant="dark" onClick={createNewsHandler}>
            Add Person
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteModal} onHide={handleDeleteClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to Delete this Person?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={deleteHandler}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PeopleList;

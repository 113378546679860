import { React } from 'react';

import Box from '@mui/material/Box';

import LinearProgress from '@mui/material/LinearProgress';

import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';

const libraries = ['places'];
const mapContainerStyle = {
  width: 'auto',
  height: '45vh',
};
const center = {
  lat: 41.50433,
  lng: -81.604301,
};

const options = {
  disableDefaultUI: true,
  zoomControl: true,
};

const Contact = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  if (loadError) return 'Error Loading Maps';
  if (!isLoaded)
    return (
      <Box className='progress_bar' sx={{ width: '100%', color: 'black' }}>
        <LinearProgress color='inherit' />
      </Box>
    );

  return (
    <>
      <h5 className='title1'>Lab Positions</h5>
      <div className='piccontainer'>
        <div className='cardcontainer'>
          <div className='cards ccard'>
            <h6
              style={{
                textAlign: 'center',
                fontWeight: '600',
                fontFamily: 'FinalSixBold',
              }}
            >
              Graduate Students
            </h6>

            <p className='contacttext'>
              We are always looking out for exceptional and creative graduate
              students from a variety of backgrounds. Please email Joe with your
              CV and research interests. Prospective Graduate Students can apply
              to our lab through the{' '}
              <a href='https://case.edu/medicine/bstp'>
                {' '}
                Biomedical Science Training Program (BSTP){' '}
              </a>{' '}
              or the{' '}
              <a href='https://case.edu/medicine/mstp/'>
                Medical Scientist Training Program (MSTP)
              </a>
              . Email Joe in advance of the application deadline whenever
              possible.
            </p>
          </div>
          
          <div className='cards ccard lastcard'>
            <h6 style={{ textAlign: 'center', fontFamily: 'FinalSixBold' }}>
              Undergraduates
            </h6>

            <p className='contacttext'>
              We are always looking for motivated and independent undergraduates
              to join the lab. We require that students stay for at least 1 year
              (including a summer) to acquire expertise in specific laboratory
              techniques and make progress on a research project. Undergraduate
              positions can receive research credit and are on a volunteer
              basis. Please email Joe with a CV (or resume), major, research
              interests, and availability.
            </p>
          </div>
          <p className='imagetext2'>
            <b>
              {' '}
              <i>Email:</i> &nbsp; jml371 AT case DOT edu{' '}
            </b>
          </p>

          <div className='mapcontainer'>
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              zoom={16}
              center={center}
              options={options}
            >
              <Marker position={center} />
            </GoogleMap>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;

import { React } from "react";
import Person from "../layouts/Person";
import { useGetPeopleQuery } from "../../slices/peopleApiSlice";
import { useSpring, a } from "react-spring";
import Loader from "../layouts/Loader";

const People = () => {
  const { data: people, isLoading, isError, error } = useGetPeopleQuery();

  const { opacity } = useSpring({
    config: { duration: 500 },
    opacity: 0,
    from: { opacity: 1 },
  });

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <>Error: {JSON.stringify(error)}</>;
  }

  return (
    <>
      <h5
        style={{
          fontFamily: "FinalSixBold",
          textAlign: "center",
          marginTop: "1.5rem",
        }}
      >
        Lab Members
      </h5>
      <div className="peoplecontainer">
        <div>
          {opacity !== 0 && (
            <a.div className="p1" style={{ opacity: opacity.to((o) => 1 - o) }}>
              {people.map((person) => (
                <div key={person.name} className="person">
                  <Person
                    person={person}
                    dataAttribute={person.dataAttribute}
                  />
                </div>
              ))}
            </a.div>
          )}
        </div>
      </div>
    </>
  );
};

export default People;

import React from "react";

import { useGetNewsQuery } from "../../slices/newsApiSlice";

import moment from "moment";

import Loader from "../layouts/Loader";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { FaNewspaper } from "react-icons/fa";
import { convertDateFormat } from "../../utils";
import { apiBaseUrl } from "../../utils/envVariable";

const News = () => {
  const { data: news, isLoading, isError, error } = useGetNewsQuery();

  const sortedNews =
    news && news.length > 0
      ? [...news].sort((a, b) => {
          return (
            moment(b.date, "YYYY/MM/DD").toDate() -
            moment(a.date, "YYYY/MM/DD").toDate()
          );
        })
      : [];

  // let momentObj = news.map((n) => moment(n.date, 'DD-MM-YYYY'));

  // var momentString = momentObj.map((n) => n.format('MMM DD, YYYY'));

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <>Error: {JSON.stringify(error)}</>;
  }

  return (
    <>
      <h4
        style={{
          textAlign: "center",
          marginTop: "1.5rem",
          fontWeight: "bold",
          fontFamily: "FinalSixBold",
        }}
      >
        News
      </h4>
      <VerticalTimeline>
        {sortedNews.map((newsItem) => (
          <VerticalTimelineElement
            key={newsItem.id}
            date={convertDateFormat(newsItem.date)}
            iconStyle={{ background: "black", color: "#fff" }}
            icon={<FaNewspaper />}
          >
            <h3 className="vertical-timeline-element-title">
              {newsItem.title}
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              {moment(newsItem.date, "YYYY/MM/DD").format("MMM DD, YYYY")}
            </h4>
            {newsItem.image && (
              <img
                src={apiBaseUrl + newsItem.image}
                alt={newsItem.title}
                style={{ maxWidth: "100%" }}
              />
            )}
            <p>{newsItem.text}</p>
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>
    </>
  );
};

export default News;

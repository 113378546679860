import { ENTER_MOBILE, EXIT_MOBILE } from './types';

const researchReducer = (state, action) => {
  switch (action.type) {
    case ENTER_MOBILE:
      return {
        ...state,
        mobile: true,
      };

    case EXIT_MOBILE:
      return {
        ...state,
        mobile: false,
      };
    default:
      return state;
  }
};

export default researchReducer;

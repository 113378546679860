import React from "react";

import Login from "../layouts/Login";
import Button from "react-bootstrap/Button";
import { useLogoutMutation } from "../../slices/usersApiSlice";
import { logout } from "../../slices/authSlice";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

const Admin = () => {
  const { userInfo } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const [logoutApiCall] = useLogoutMutation();

  const logoutHandler = async () => {
    try {
      await logoutApiCall().unwrap();
      dispatch(logout());
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {userInfo ? (
        <>
          <div>
            <h5
              style={{
                textAlign: "center",
                marginTop: "1.5rem",
                fontWeight: "bold",
                fontFamily: "FinalSixBold",
              }}
            >
              Admin Page
            </h5>
            <div className="adminbuttoncontainer">
              <Link className="adminlink" to="/admin/news">
                <Button variant="dark" type="submit" className="adminbutton">
                  Edit News Page
                </Button>{" "}
              </Link>
              <Link className="adminlink" to="/admin/publications">
                <Button variant="dark" type="submit" className="adminbutton">
                  Edit Publications Page
                </Button>{" "}
              </Link>
              <Link className="adminlink" to="/admin/people">
                <Button variant="dark" type="submit" className="adminbutton">
                  Edit People Page
                </Button>{" "}
              </Link>
              <Button
                variant="dark"
                type="submit"
                className="adminbutton"
                style={{ marginTop: "1rem" }}
                onClick={logoutHandler}
              >
                Logout
              </Button>{" "}
            </div>{" "}
          </div>
        </>
      ) : (
        <Login />
      )}
    </>
  );
};

export default Admin;
